/* inputs  */
label,
.form-label {
  font-weight: 500;
  margin-bottom: 5px;
  color: #2c2c2c;
  font-size: 0.95rem;
}

.radio-label {
  font-weight: 300;
  margin-bottom: 0px;
  font-size: 1rem;
}

.form-control {
  display: block;
  height: 40px;
  color: rgb(0, 0, 0);
  box-shadow: none !important;
  border-radius: 0px;
  border-color: rgb(206, 212, 218);
}

.form-control:disabled {
  background-color: hsl(0, 0%, 95%);
  color: hsl(0, 0%, 60%);
}

/* react select */
.react-select__control {
  border: 1px solid rgb(206, 212, 218) !important;
  font-size: 1rem !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  min-height: 40px !important;
}

.react-select__control--is-focused {
  border-color: var(--theme-color) !important;
}

.react-select__menu {
  z-index: 2 !important;
}

::placeholder {
  color: hsl(0, 0%, 50%);
}

.react-select__placeholder {
  color: hsl(0, 0%, 50%);
}

.react-select__option {
  padding: 5px !important;
  font-size: 14px !important;
}

.react-select__indicator-separator {
  display: none;
}

.form-control:focus {
  border-color: var(--theme-color);
  outline: none;
}

option {
  outline: none;
}

.form-group {
  margin-bottom: 1rem;
}

.form-spacing-large .form-group {
  margin-bottom: 2rem;
}

.form-uppercase-label .form-label {
  text-transform: uppercase;
}

textarea {
  min-height: 100px;
}

.input-messages {
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-messages li {
  margin-top: 2px;
}

.form-asterix {
  position: relative;
  top: 0;
  color: #0168fa;
}

.errMsg {
  color: red;
}

.infoText {
  color: #9f9f9f;
}

/* react date picker  */
.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker {
  width: 100% !important;
  font-family: inherit !important;
}

.react-datepicker__year-wrapper {
  max-width: 275px !important;
}

/* mutiple checkbox ip */
.multiple-checkbox input[type="checkbox"] + label {
  display: block;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 0;
}

.multiple-checkbox input[type="checkbox"] {
  display: none;
}

.multiple-checkbox input[type="checkbox"] + label:before {
  content: "\2714";
  border: 1px solid #b4b4b4;
  border-radius: 0.2em;
  display: inline-flex;
  width: 1.2em;
  height: 1.2em;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.multiple-checkbox input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

.multiple-checkbox input[type="checkbox"]:checked + label:before {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: #fff;
}

.multiple-checkbox input[type="checkbox"]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

.multiple-checkbox input[type="checkbox"]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #e1e1e1 !important;
  border-color: #e1e1e1 !important;
  color: #000 !important;
}

/** Switch
 -------------------------------------*/

.switch input {
  position: absolute;
  opacity: 0;
}

.switch {
  display: inline-block;
  font-size: 20px; /* 1 */
  height: 1em;
  width: 2em;
  background: #bdb9a6;
  border-radius: 1em;
  margin-bottom: 0 !important;
}

.switch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #fff;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

.switch input:checked + div {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.switch-active {
  background: #28a745;
}
.attendance-switch .attendance-text{margin-right:10px}
.attendance-height{min-height:90px}

.more-icon{color:var(--theme-color);border:1px solid;border-radius:5px;display:block;text-align:center;margin-top:2px;padding:4px}
.more-icon.disabled{opacity:.6}
.more-icon.minus{color:red}