@font-face {
    font-family: "SF Pro Display";
    src: url(../fonts/sf-pro-display_light.woff2);
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url(../fonts/sf-pro-display_regular.woff2);
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url(../fonts/sf-pro-display_semibold.woff2);
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "SF Pro Display";
    src: url(../fonts/sf-pro-display_bold.woff2);
    font-weight: 600;
    font-display: swap;
}